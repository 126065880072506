import qs from "qs";
import axiosResiInstance from "../http/axiosResi";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { getTrackingId } from "./track";
import { Capacitor } from "@capacitor/core";

export async function fetchLogin(email, password) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        email,
        password,
        tracking_id: trackingId,
        device_id: visitorId,
    });

    return axiosResiInstance.post("/auth/login", data);
}

export async function refreshTokens() {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();

    try {
        const res = await axiosResiInstance.post("/auth/refresh-tokens", {
            device_id: visitorId,
        });

        return res.data.access_token;
    } catch (e) {}

    return null;
}

export async function fetchSignup(email, password, isAgent) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        email,
        password,
        is_real_estate_professional: isAgent ? "1" : "0",
        tracking_id: trackingId,
        device_id: visitorId,
    });

    return axiosResiInstance.post("/auth/signup", data);
}

export async function fetchFullSignup(
    email,
    password,
    isAgent,
    fullName,
    phone
) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        email,
        password,
        full_name: fullName,
        phone,
        is_real_estate_professional: isAgent ? "1" : "0",
        tracking_id: trackingId,
        device_id: visitorId,
    });

    return axiosResiInstance.post("/auth/full-signup", data);
}

export async function fetchProfile() {
    try {
        const res = await axiosResiInstance.get("/auth/profile");
        return res.data;
    } catch (e) {}

    return null;
}

export async function destroyToken() {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();

    await axiosResiInstance.post("/auth/logout", {
        device_id: visitorId,
    });
}

export async function fetchReactivate(email, password) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        email,
        password,
        tracking_id: trackingId,
        device_id: visitorId,
    });

    return axiosResiInstance.post("/auth/reactivate", data);
}

export async function fetchGoogleLogin(authCode) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        code: authCode,
        tracking_id: trackingId,
        device_id: visitorId,
        platform: Capacitor.getPlatform(),
    });

    return axiosResiInstance.post("/auth/google", data);
}

export async function fetchFacebookLogin(accessToken) {
    const fp = await FingerprintJS.load();

    const { visitorId } = await fp.get();
    const trackingId = await getTrackingId();

    const data = qs.stringify({
        access_token: accessToken,
        tracking_id: trackingId,
        device_id: visitorId,
    });

    return axiosResiInstance.post("/auth/facebook", data);
}

export function getUserDashboardRoute(profile) {
    if (profile.permissions.includes("agent-leads.list")) {
        return "/user/opportunities";
    } else {
        return "/user";
    }
}
