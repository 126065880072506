export default {
    title: "Homes For Sale, Homes For Rent, Real Estate Info, Real Estate Leasing, Rentals, Agents, Mortgages | Residential.com™",
    description:
        "Residential.com is a Nation Wide Real Estate Search Site. Search homes for sale and rent, get access to real estate experts and more!",
    themeColor: "#ff0a2e",
    backgroundColor: "#fff",
    openGraph: {
        type: "website",
        locale: "en_US",
        url: "https://www.residential.com",
        title: "Residential.com™",
        description:
            "Residential.com is a Nation Wide Real Estate Search Site. Search homes for sale and rent, get access to real estate experts and more!",
        site_name: "Residential.com",
        images: [
            {
                url: process.env.CDN_PREFIX + "/static/img/residential.jpg",
                width: 2000,
                height: 1426,
                alt: "Residential.com",
            },
        ],
    },
    twitter: {
        handle: "@Residentialdot",
        cardType: "summary_large_image",
    },
    facebook: {
        appId: process.env.FACEBOOK_APP_ID,
    },
};
