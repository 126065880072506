import { parseCookies, setCookie } from "nookies";
import { format } from "date-fns";
import axiosResiInstance from "../http/axiosResi";

export const COOKIE_TRACKING_ID = "zbid";

export default async function track(type, data) {
    const trackingId = await getTrackingId();

    if (!trackingId) {
        return;
    }

    axiosResiInstance
        .post("/person-log", {
            tracking_id: trackingId,
            start_date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxx"),
            type,
            data,
        })
        .catch((e) => {});
}

export const getListingView = async (listings) => {
    const trackingId = await getTrackingId();

    if (!trackingId) {
        return {};
    }

    if (!listings || listings.length === 0) {
        return {};
    }

    let listingIds = [];
    listings.forEach((listing) => {
        listingIds.push(listing.id);
    });

    let views = {};

    try {
        const viewData = await axiosResiInstance.get("/listing-view", {
            params: {
                tracking_id: trackingId,
                listings: listingIds.join(),
            },
        });

        if (viewData && viewData.data.data) {
            views = viewData.data.data;
        }
    } catch (e) {}

    return views;
};

export const getTrackingId = async () => {
    let cookies = parseCookies();

    if (
        !cookies.hasOwnProperty(COOKIE_TRACKING_ID) ||
        !cookies[COOKIE_TRACKING_ID]
    ) {
        const response = await axiosResiInstance.post("/person");

        setCookie(null, COOKIE_TRACKING_ID, response.data.tracking_id, {
            path: "/",
        });

        cookies = parseCookies();

        if (
            !cookies.hasOwnProperty(COOKIE_TRACKING_ID) ||
            !cookies[COOKIE_TRACKING_ID]
        ) {
            return null;
        }
    }

    return cookies[COOKIE_TRACKING_ID];
};
