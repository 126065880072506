import Router from "next/router";

import axios from "axios";
import { useEffect } from "react";
import { useAuth, authStore } from "../components/common/Auth";

const DEBUG = process.env.NODE_ENV === "development";

const axiosResiInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.RESI_API_URL,
});

const AxiosInterceptor = ({ children }) => {
    const { logout } = useAuth();
    const { isAuthenticated } = authStore.getState();

    useEffect(() => {
        const interceptor = axiosResiInstance.interceptors.request.use(
            (config) => {
                const { accessToken } = authStore.getState();
                config.headers.Authorization = accessToken
                    ? `Bearer ${accessToken}`
                    : "";
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        return () => axiosResiInstance.interceptors.request.eject(interceptor);
    }, []);

    useEffect(() => {
        const resInterceptor = (response) => {
            return response;
        };

        const errInterceptor = async (error) => {
            if (error.response) {
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    if (isAuthenticated) {
                        logout();
                    }
                } else if (error.response.status === 429) {
                    const tooManyRequestsUrl = "/too-many-requests";

                    if (tooManyRequestsUrl === Router.pathname) {
                        Router.reload();
                    } else {
                        await Router.push(tooManyRequestsUrl);
                    }
                } else if (DEBUG) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if (error.request) {
                if (DEBUG) {
                    console.log(error.request);
                }
            } else if (DEBUG) {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }

            return Promise.reject(error);
        };

        const interceptor = axiosResiInstance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        return () => axiosResiInstance.interceptors.response.eject(interceptor);
    }, []);

    return children;
};

export { AxiosInterceptor };
export default axiosResiInstance;
