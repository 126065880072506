import "../css/reset.css";
import "../css/base.css";
import "../css/search/fade.css";
import "../css/common/listing-badge.css";
import "../css/common/scrollbar.css";
import "../static/css/image-gallery.css";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect, useState } from "react";
import Head from "next/head";
import requestIp from "request-ip";
import { DefaultSeo } from "next-seo";

import AuthProvider from "../components/common/Auth";
import IpContext from "../components/context/Ip";
import HistoryProvider from "../components/common/History";
import { AxiosInterceptor } from "../http/axiosResi";

import * as fb from "../http/fb";
import * as ga4 from "../http/ga4";
import * as gtm from "../http/gtm";
import * as googleAw from "../http/googleAw";

import DEFAULT_SEO from "../service/seo.config";
import {
    SEO_KEYWORDS,
    SEO_VIEWPORT,
    SEO_FORMAT_DETECTION,
    SEO_GOOGLE,
    SEO_GOOGLE_SITE_VERIFICATION,
} from "../service/seo";

const handleRouteChange = () => {
    fb.pageView();
    ga4.pageView();
};

const MyApp = (props) => {
    const { Component, pageProps, router } = props;

    const [history, setHistory] = useState([]);

    useEffect(() => {
        init();

        router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, []);

    useEffect(() => {
        pushToHistory(router);
    }, [router.pathname, router.query, router.asPath]);

    const pushToHistory = (route) => {
        let newHistory = [];

        if (history.length === 0) {
            setHistory([
                {
                    pathname: route.pathname,
                    query: route.query,
                    asPath: route.asPath,
                },
            ]);

            return true;
        }

        if (history[history.length - 1].asPath !== router.asPath) {
            newHistory = [
                ...history,
                {
                    pathname: route.pathname,
                    query: route.query,
                    asPath: route.asPath,
                },
            ];

            setHistory(newHistory);
        }
    };

    const handleHistoryChange = (newHistory) => {
        setHistory(newHistory);
    };

    const init = () => {
        pushToHistory(router);

        if (typeof document !== "undefined" && document.documentElement) {
            document.documentElement.lang = "en";
        }

        if (typeof window !== "undefined" && pageProps.ip) {
            window.resiClientIp = pageProps.ip;
        }

        fb.init();
        ga4.init();
        googleAw.init();
        gtm.init();
    };

    const pageKey = router.asPath.split("?")[0];

    return (
        <>
            <Head>
                <meta httpEquiv="content-language" content="en-us" />
                <meta name="viewport" content={SEO_VIEWPORT} />
                <meta name="keywords" content={SEO_KEYWORDS} />
                <meta name="format-detection" content={SEO_FORMAT_DETECTION} />
                <meta name="google" content={SEO_GOOGLE} />
                <meta
                    name="google-site-verification"
                    content={SEO_GOOGLE_SITE_VERIFICATION}
                />
                <meta name="theme-color" content={DEFAULT_SEO.themeColor} />
                <meta
                    name="msvalidate.01"
                    content="2C8880CBBB1547D7C22C782BC4FDA40B"
                />
                <meta name="msapplication-TileColor" content="#ff0a2e" />
                <meta
                    name="msapplication-TileImage"
                    content="/favicon/mstile-144x144.png"
                />

                <link
                    rel="apple-touch-icon"
                    href="/favicon/apple-touch-icon.png"
                />
                <link
                    rel="mask-icon"
                    href="/favicon/safari-pinned-tab.svg"
                    color="#ff0a2e"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon/favicon-16x16.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/favicon/android-chrome-192x192.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="512x512"
                    href="/favicon/android-chrome-512x512.png"
                />
                <link rel="shortcut icon" href="/favicon.ico" />

                <link
                    rel="manifest"
                    href="/manifest.json"
                    crossOrigin="use-credentials"
                />
            </Head>
            <DefaultSeo {...DEFAULT_SEO} />
            <AuthProvider>
                <AxiosInterceptor>
                    <HistoryProvider
                        history={history}
                        onChange={handleHistoryChange}
                    >
                        <IpContext.Provider value={pageProps.ip}>
                            <Component key={pageKey} {...pageProps} />
                        </IpContext.Provider>
                    </HistoryProvider>
                </AxiosInterceptor>
            </AuthProvider>
        </>
    );
};

MyApp.getInitialProps = async (appContext) => {
    const { Component, ctx } = appContext;

    let componentProps = null;
    let ip = null;

    if (Component && typeof Component.getInitialProps === "function") {
        componentProps = await Component.getInitialProps(ctx);
    }

    // IP ADDRESS
    if (ctx && ctx.req) {
        ip = requestIp.getClientIp(ctx.req);
    }

    if (!ip && componentProps && componentProps.ip) {
        ip = componentProps.ip;
    }

    if (!ip && typeof window !== "undefined" && window.resiClientIp) {
        ip = window.resiClientIp;
    }

    // pageProps
    let pageProps = {
        ip,
    };

    if (componentProps) {
        delete componentProps["ip"];
        delete componentProps["resiUserAgent"];

        pageProps = {
            ...pageProps,
            ...componentProps,
        };
    }

    return { pageProps };
};

export default MyApp;
