import ReactGA from "react-ga4";

const IS_DEV = process.env.NODE_ENV === "development";
const IS_BROWSER = typeof window !== "undefined";
const GOOGLE_TRACKING_ID = process.env.GOOGLE_TRACKING_ID
    ? process.env.GOOGLE_TRACKING_ID
    : null;

const IS_GA4 = !IS_DEV && IS_BROWSER && GOOGLE_TRACKING_ID;

// https://www.npmjs.com/package/react-ga4
export function init() {
    if (!IS_GA4 || window.GA4_INITIALIZED) {
        return;
    }

    // Initialize
    ReactGA.initialize(GOOGLE_TRACKING_ID);

    window.GA4_INITIALIZED = true;

    pageView();
}

export function pageView() {
    if (!IS_GA4 || !window.GA4_INITIALIZED) {
        return;
    }

    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: window.location.title,
    });
}
