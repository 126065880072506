import ReactTagManager from "@sooro-io/react-gtm-module";

export const GTM_TRIGGER_ID_CONTACT = "gtm-id-contact";
export const GTM_TRIGGER_ID_GET_MORE_INFO = "gtm-id-get-more-info";
export const GTM_TRIGGER_ID_REQUEST = "gtm-id-request";
export const GTM_TRIGGER_ID_TEST_OPPORTUNITY = "gtm-id-test-opportunity";

const IS_DEV = process.env.NODE_ENV === "development";
const IS_BROWSER = typeof window !== "undefined";
const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID
    ? process.env.GOOGLE_TAG_MANAGER_ID
    : null;
const GOOGLE_TAG_MANAGER_AUTH = process.env.GOOGLE_TAG_MANAGER_AUTH
    ? process.env.GOOGLE_TAG_MANAGER_AUTH
    : null;
const GOOGLE_TAG_MANAGER_PREVIEW = process.env.GOOGLE_TAG_MANAGER_PREVIEW
    ? process.env.GOOGLE_TAG_MANAGER_PREVIEW
    : null;

const IS_GTM =
    !IS_DEV &&
    IS_BROWSER &&
    GOOGLE_TAG_MANAGER_ID &&
    GOOGLE_TAG_MANAGER_AUTH &&
    GOOGLE_TAG_MANAGER_PREVIEW;

// https://github.com/sooro-io/react-gtm-module
export function init() {
    if (!IS_GTM || window.GTM_INITIALIZED) {
        return;
    }

    ReactTagManager.initialize({
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: GOOGLE_TAG_MANAGER_AUTH,
        preview: GOOGLE_TAG_MANAGER_PREVIEW,
    });

    window.GTM_INITIALIZED = true;
}
