const IS_DEV = process.env.NODE_ENV === "development";
const IS_BROWSER = typeof window !== "undefined";
const GOOGLE_AW_PIXELS = process.env.GOOGLE_AW_PIXELS
    ? process.env.GOOGLE_AW_PIXELS
    : null;

const IS_GOOGLE_AW_PIXEL =
    !IS_DEV &&
    IS_BROWSER &&
    GOOGLE_AW_PIXELS;

const getPixels = () => {
    const IS_GTAG = IS_BROWSER && typeof window.gtag === "function";

    let pixels = null;

    if (!IS_GOOGLE_AW_PIXEL || !IS_GTAG) {
        return pixels;
    }

    pixels = [];

    GOOGLE_AW_PIXELS.split(",").forEach((pixel) =>
        pixels.push(pixel.split("/"))
    );

    return pixels;
};

export function init() {
    const IS_GTAG = IS_BROWSER && typeof window.gtag === "function";

    if (!IS_GOOGLE_AW_PIXEL || window.GOOGLE_AW_INITIALIZED || !IS_GTAG) {
        return;
    }

    const pixels = getPixels();

    if (!pixels) {
        return;
    }

    // Google tag config
    for (let i = 0; i < pixels.length; i++) {
        window.gtag("config", pixels[i][0]);
    }

    window.GOOGLE_AW_INITIALIZED = true;
}

export const trackConversion = () => {
    const IS_GTAG = IS_BROWSER && typeof window.gtag === "function";

    if (!IS_GOOGLE_AW_PIXEL || !IS_GTAG) {
        return;
    }

    const pixels = getPixels();

    // Event snippet for Submit lead form conversion page
    for (let i = 0; i < pixels.length; i++) {
        window.gtag("event", "conversion", {
            send_to: pixels[i][0] + "/" + pixels[i][1],
        });
    }
};
