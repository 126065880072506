const IS_DEV = process.env.NODE_ENV === "development";
const IS_BROWSER = typeof window !== "undefined";
const FACEBOOK_PIXEL_IDS = process.env.FACEBOOK_PIXEL_IDS
    ? process.env.FACEBOOK_PIXEL_IDS.split(",")
    : null;

const IS_FB_PIXEL = !IS_DEV && IS_BROWSER && FACEBOOK_PIXEL_IDS;

let ReactPixel = null;

export function init() {
    if (!IS_FB_PIXEL || window.FB_PIXEL_INITIALIZED) {
        return;
    }

    // Load
    const reactFacebookPixel = require("./reactFacebookPixel");
    ReactPixel = reactFacebookPixel.load();

    // Init all pixels
    FACEBOOK_PIXEL_IDS.forEach((fbPixelId) => ReactPixel.init(fbPixelId));

    window.FB_PIXEL_INITIALIZED = true;

    pageView();
}

export const pageView = () => {
    if (!ReactPixel) {
        return;
    }

    ReactPixel.pageView();
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
export const trackConversion = () => {
    if (!ReactPixel) {
        return;
    }

    ReactPixel.track("Lead");
};
