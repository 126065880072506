import React, { useContext } from "react";
import clone from "../../helper/clone";

const HistoryContext = React.createContext(null);

const HistoryProvider = ({ children, history, onChange }) => {
    const getPrevious = () => {
        if (history.length <= 1) {
            return null;
        }

        return history[history.length - 2];
    };

    const pop = () => {
        const newHistory = clone(history);
        newHistory.pop();

        onChange(newHistory);
    };

    const value = {
        pop,
        getPrevious,
    };

    return (
        <HistoryContext.Provider value={value}>
            {children}
        </HistoryContext.Provider>
    );
};

export default HistoryProvider;

export const useHistory = () => useContext(HistoryContext);
