const isRent = (listing) => {
    let category = listing;

    if (typeof listing === "object") {
        category = listing.category.name;
    }

    return ["RENT", "LEASE"].includes(category);
};

export default isRent;
