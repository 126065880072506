import noPhoto from "../static/img/no-image.png";
import noPhotoAvatar from "../static/img/avatar.png";
import noPhotoBlog from "../static/img/blog-thumb.png";

export const LISTING_THUMB_170_100 = "170_100";
export const LISTING_THUMB_340_200 = "340_200";
export const LISTING_THUMB_420_247 = "420_247";
export const LISTING_THUMB_420_294 = "420_294";
export const LISTING_THUMB_640_376 = "640_376";
export const LISTING_THUMB_900_529 = "900_529";

export const THUMB_64_64 = "64_64";
export const THUMB_150_112 = "150_112";
export const THUMB_150_150 = "150_150";
export const THUMB_200_270 = "200_270";
export const THUMB_250_250 = "250_250";
export const THUMB_360_360 = "360_360";
export const THUMB_640_480 = "640_480";
export const THUMB_2048_2048 = "2048_2048";

export function getListingPhotoUrl(photoId, size) {
    if (!photoId) {
        return noPhoto.src;
    }

    if (photoId.match(/^https?:\/\//)) {
        return photoId;
    }

    return process.env.PHOTO_CDN_URL + size + "/" + photoId + ".jpg";
}

export function getAvatarPhotoUrl(photoId, size) {
    if (!photoId) {
        return noPhotoAvatar.src;
    }

    return process.env.PHOTO_CDN_URL + size + "/" + photoId + ".jpg";
}

export function getBlogPhotoUrl(photoId, size) {
    if (!photoId) {
        return noPhotoBlog.src;
    }

    return process.env.PHOTO_CDN_URL + size + "/" + photoId + ".jpg";
}

export function onError(target, streetViewPhoto) {
    if (target.src.includes("maps.googleapis.com")) {
        return;
    }

    target.onerror = null;
    target.src = streetViewPhoto;

    return target;
}
