import isRent from "../helper/isRent";
import moneyFormatter from "../helper/moneyFormatter";
import { getListingPhotoUrl, LISTING_THUMB_900_529 } from "./photo";

export const SEO_KEYWORDS =
    "residential, real estate, real estate leasing, rentals, agents, mortgages, property information, " +
    "property details, property stats, homes for sale, homes for rent, median home prices, homes, home, " +
    "houses, house, condos, property, home gather, foreclosures, short sales, hud housing";

export const SEO_VIEWPORT =
    "width=device-width, initial-scale=1, maximum-scale=2";
export const SEO_FORMAT_DETECTION = "telephone=no";
export const SEO_GOOGLE = "notranslate";
export const SEO_GOOGLE_SITE_VERIFICATION =
    "DZj6Y_EJ7i6TIS7o8K_7-TJD72b7yX6WJxTOTlHIsp4";

const TITLE_MAX_LENGTH = 100;

/**
 * -----------------------------------
 * no-index, no-follow
 * -----------------------------------
 */
export const getSeoNoIndex = () => {
    return {
        noindex: true,
    };
};

/**
 * -----------------------------------
 * INDEX PAGES
 * -----------------------------------
 */
export const getSeoIndex = (category) => {
    let title;

    if (category === "sale") {
        title =
            "Homes For Sale, Real Estate Info, Agents, Mortgages | Residential.com™";
    } else {
        title =
            "Homes For Rent, Real Estate Leasing, Rentals, Agents | Residential.com™";
    }

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

/**
 * -----------------------------------
 * PAGES
 * -----------------------------------
 */
export const getSeoMyProperty = () => {
    const title =
        "Residential.com™: Manage your Property, List Home For Sale, List Home For Rent";

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

export const getSeoLoans = () => {
    const title = "Get a Residential Home Loan - Mortgage | Residential.com™";

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

/**
 * -----------------------------------
 * AGENT PAGES
 * -----------------------------------
 */
export const getSeoFindAgent = () => {
    const title =
        "Search Local Real Estate Agents, Read Reviews & More | Residential.com™";

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

export const getSeoAgents = (location) => {
    if (!location || !location.value) {
        return {};
    }

    const name =
        location.value.type === "zip"
            ? location.value.location.code
            : location.value.location.name;
    const title =
        "Real Estate Agents in " +
        name +
        " - " +
        location.label +
        " Realtors & Reviews | Residential.com™";

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

export const getSeoAgent = (agent, location = null) => {
    if (!agent) {
        return {};
    }

    let title = null;

    if (location) {
        title =
            agent.full_name +
            " Real Estate Agent in " +
            location.name_with_state +
            " - " +
            agent.full_name +
            " Reviews | Residential.com™";
    } else {
        title =
            agent.full_name +
            " Real Estate Agent - " +
            agent.full_name +
            " Reviews | Residential.com™";
    }

    return {
        title,
        shortenTitle: shortenTitle(title),
        openGraph: {
            title,
        },
    };
};

/**
 * -----------------------------------
 * SEARCH PAGES
 * -----------------------------------
 */
export const getSeoSearch = (location, category, listingsTotal) => {
    if (!location || !location.location || !category) {
        return {};
    }

    const name =
        location.type === "zip"
            ? location.location.code
            : location.location.name;
    const label = location.label;

    let title;
    let description;

    listingsTotal =
        listingsTotal && listingsTotal > 0 ? " " + listingsTotal : "";

    if (category === "sale") {
        title =
            name +
            " Homes For Sale - " +
            label +
            " Real Estate | Residential.com™";

        description =
            "Search" +
            listingsTotal +
            " homes for sale in " +
            (location.type === "zip" ? name : label) +
            ". " +
            "View all " +
            name +
            " real estate listings, new properties, photos and view property data on Residential.com.";
    } else {
        title =
            name +
            " Homes For Rent - " +
            label +
            " Homes For Lease | Residential.com™";

        description =
            "Search" +
            listingsTotal +
            " Rental Properties in " +
            label +
            ". " +
            "Find condos, townhomes and single family homes for lease in " +
            name +
            " on Residential.com.";
    }

    return {
        title,
        shortenTitle: shortenTitle(title),
        description,
        openGraph: {
            title,
            description,
        },
    };
};

export const getSeoSearchKeywords = (location, category) => {
    if (!location || !location.location || !category) {
        return null;
    }

    let name = "";
    let shortLabel = "";
    let label = location.label;

    if (location.type === "zip") {
        name = location.location.code;

        shortLabel =
            location.location.code +
            (location.location.cities && location.location.cities.length > 0
                ? " " + location.location.cities[0].name
                : "");
    } else {
        name = location.location.name;
        shortLabel = location.location.name;
    }

    if (!name || !shortLabel || !label) {
        return null;
    }

    name = name.toLowerCase().replace(/, /g, " ");
    shortLabel = shortLabel.toLowerCase().replace(/, /g, " ");
    label = label.toLowerCase().replace(/, /g, " ");

    let keywords;

    if (category === "sale") {
        keywords =
            "homes in " +
            name +
            ", " +
            "search " +
            name +
            " homes for sale, " +
            "search real estate in " +
            name +
            ", " +
            name +
            " homes, " +
            name +
            " real estate, " +
            name +
            " mls, " +
            name +
            " mls listings, " +
            name +
            " properties, " +
            name +
            " properties for sale, " +
            name +
            " homes for sale, " +
            name +
            " residential.com, " +
            name +
            " residential homes, " +
            name +
            " residential, " +
            shortLabel +
            " homes for sale, " +
            shortLabel +
            " real estate for sale, " +
            label +
            ", " +
            name +
            " property search, " +
            name +
            " short sales, " +
            name +
            " foreclosures, " +
            name +
            " auctions, " +
            name +
            " hud homes";
    } else {
        keywords =
            name +
            " homes for rent, " +
            name +
            " rental properties, " +
            name +
            " homes for lease, " +
            name +
            " real estate for lease, " +
            label +
            " rentals, " +
            label +
            " real estate for lease, " +
            label +
            " homes for rent, " +
            label +
            " for lease, " +
            "homes for rent in " +
            label;
    }

    return keywords;
};

/**
 * -----------------------------------
 * PROPERTY PAGE
 * -----------------------------------
 */
export const getSeoListing = (listing, photos) => {
    if (!listing) {
        return {};
    }

    const rent = isRent(listing);
    const { participant } = listing;

    const price =
        listing.price && listing.price > 0
            ? "price " +
              moneyFormatter(0).format(listing.price) +
              (rent ? "/mo " : " ")
            : "";

    const address =
        listing.address +
        ", " +
        listing.city.name +
        ", " +
        listing.state.abbr +
        " " +
        listing.zip.code;

    const bedrooms =
        listing.bedrooms && listing.bedrooms > 0
            ? listing.bedrooms + (listing.bedrooms === 1 ? " Bed, " : " Beds, ")
            : "";

    const bathrooms =
        listing.bathrooms && listing.bathrooms > 0
            ? listing.bathrooms +
              (listing.bathrooms === 1 ? " Bath, " : " Baths, ")
            : "";

    const propertySubType = listing.property_sub_type
        ? listing.property_sub_type.label
        : "";

    const livingArea =
        listing.living_area && listing.living_area > 0
            ? listing.living_area + " Sqft, "
            : "";

    const photoText =
        photos && photos.length > 0 ? photos.length + " of Photos - " : "";

    let mlsNumber;

    if (participant) {
        mlsNumber = participant.mls
            ? "MLS #" + participant.mls.number + " "
            : "";
    } else {
        mlsNumber = listing.mls_number
            ? "MLS #" + listing.mls_number + " "
            : "";
    }

    const title =
        address +
        " - " +
        bedrooms +
        bathrooms +
        propertySubType +
        (photoText || mlsNumber ? " - " : " ") +
        photoText +
        mlsNumber +
        "| Residential.com™";

    const description =
        address +
        ", " +
        (propertySubType ? propertySubType + ", " : "") +
        livingArea +
        bedrooms +
        bathrooms +
        "listed on Residential.com " +
        price +
        "in " +
        listing.city.name +
        ", " +
        listing.state.name +
        ".";

    let primaryPhoto = listing.primary_photo ? listing.primary_photo : null;
    if (!primaryPhoto && photos && photos.length > 0) {
        primaryPhoto = photos[0];
    }

    let images;

    if (primaryPhoto) {
        images = [
            {
                url: getListingPhotoUrl(primaryPhoto, LISTING_THUMB_900_529),
                width: 900,
                height: 529,
                alt: "Residential.com",
            },
        ];
    } else {
        images = [
            {
                url: process.env.CDN_PREFIX + "/static/img/residential.jpg",
                width: 2000,
                height: 1426,
                alt: "Residential.com",
            },
        ];
    }

    return {
        title,
        shortenTitle: shortenTitle(title),
        description,
        openGraph: {
            title,
            description,
            images,
            url: `https://www.residential.com/l/${listing.url}`,
        },
    };
};

export const getSeoProperty = (property) => {
    const address =
        property.address +
        ", " +
        property.city.name +
        ", " +
        property.state.abbr +
        " " +
        property.zip.code;

    const bedrooms =
        property.bedrooms && property.bedrooms > 0
            ? property.bedrooms +
              (property.bedrooms === 1 ? " Bed, " : " Beds, ")
            : "";

    const bathrooms =
        property.bathrooms && property.bathrooms > 0
            ? property.bathrooms +
              (property.bathrooms === 1 ? " Bath, " : " Baths, ")
            : "";

    const propertySubType = property.property_sub_type
        ? property.property_sub_type.label
        : "";

    const livingArea =
        property.living_area && property.living_area > 0
            ? property.living_area + " Sqft, "
            : "";

    const title =
        address +
        " - " +
        bedrooms +
        bathrooms +
        propertySubType +
        " | Residential.com™";

    const description =
        address +
        ", " +
        (propertySubType ? propertySubType + ", " : "") +
        livingArea +
        bedrooms +
        bathrooms +
        "listed on Residential.com " +
        "in " +
        property.city.name +
        ", " +
        property.state.name +
        ".";

    return {
        title,
        shortenTitle: shortenTitle(title),
        description,
        openGraph: {
            title,
            description,
            url: `https://www.residential.com/p/${property.url}`,
        },
    };
};

export const getSeoListingKeywords = (listing) => {
    const rent = isRent(listing);
    let address = listing.address;
    let fullAddress =
        address +
        " " +
        listing.city.name +
        " " +
        listing.state.abbr +
        " " +
        listing.zip.code;

    address = address.toLowerCase();
    fullAddress = fullAddress.toLowerCase();

    let keywords;

    if (rent) {
        keywords =
            fullAddress +
            ", " +
            address +
            " home for rent, " +
            address +
            " for lease, " +
            address +
            " residential, " +
            fullAddress +
            " property, " +
            address +
            " mls, " +
            fullAddress +
            " mls listing, " +
            "property " +
            fullAddress;
    } else {
        keywords =
            fullAddress +
            ", " +
            address +
            " home for sale, " +
            address +
            " for sale, " +
            address +
            " residential, " +
            fullAddress +
            " property, " +
            address +
            " mls, " +
            fullAddress +
            " mls listing, " +
            "property " +
            fullAddress;
    }

    return keywords;
};

export const getSeoPropertyKeywords = (property) => {
    let address = property.address;
    let fullAddress =
        address +
        " " +
        property.city.name +
        " " +
        property.state.abbr +
        " " +
        property.zip.code;

    address = address.toLowerCase();
    fullAddress = fullAddress.toLowerCase();

    const keywords =
        fullAddress +
        ", " +
        address +
        " home for sale, " +
        address +
        " for sale, " +
        address +
        " residential, " +
        fullAddress +
        " property, " +
        address +
        " mls, " +
        fullAddress +
        " mls listing, " +
        "property " +
        fullAddress;

    return keywords;
};

const shortenTitle = (title) => {
    title = title.replace(" | Residential.com™", "");
    title = title.replace("|Residential.com™", "");
    title = title.replace("Residential.com™", "");

    return title.length > TITLE_MAX_LENGTH
        ? title.slice(0, TITLE_MAX_LENGTH - 4) + "..."
        : title;
};
