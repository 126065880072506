import React, { useContext, useEffect } from "react";
import useInterval from "../../hooks/useInterval";
import { destroyToken, fetchProfile, refreshTokens } from "../../service/auth";
import { useSyncExternalStore } from "react";

const AuthContext = React.createContext({
    accessToken: null,
    profile: null,
    loaded: false,
    isAuthenticated: false,
    refetchProfile: null,
    login: null,
    logout: null,
});

let authState = {
    accessToken: null,
    profile: null,
    loaded: false,
    isAuthenticated: false,
};
let subscribers = new Set();

export const authStore = {
    login(accessToken, profile) {
        authState = {
            ...authState,
            accessToken: accessToken,
            profile: profile,
            loaded: true,
            isAuthenticated: true,
        };

        subscribers.forEach((callback) => {
            callback();
        });
    },

    logout() {
        authState = {
            ...authState,
            accessToken: null,
            profile: null,
            isAuthenticated: false,
        };

        subscribers.forEach((callback) => {
            callback();
        });
    },

    setAccessToken(accessToken) {
        authState = {
            ...authState,
            accessToken: accessToken,
            isAuthenticated: !!authState.profile,
        };

        subscribers.forEach((callback) => {
            callback();
        });
    },

    setProfile(profile) {
        authState = {
            ...authState,
            profile: profile,
            loaded: true,
            isAuthenticated: !!authState.accessToken,
        };

        subscribers.forEach((callback) => {
            callback();
        });
    },

    setLoaded() {
        authState = {
            ...authState,
            loaded: true,
        };

        subscribers.forEach((callback) => {
            callback();
        });
    },

    subscribe(callback) {
        subscribers.add(callback);
        return () => subscribers.delete(callback);
    },

    getState() {
        return authState;
    },
};

const AuthProvider = ({ children }) => {
    const state = useSyncExternalStore(
        authStore.subscribe,
        authStore.getState,
        authStore.getState
    );

    useInterval(async () => {
        // don't refresh tokens in case user is not logged in
        if (state.loaded && !state.accessToken) {
            return;
        }

        const accessToken = await refreshTokens();
        authStore.setAccessToken(accessToken);
    }, (14 * 60 + 50) * 1000); // 14min 50sec

    useEffect(() => {
        async function loadTokens() {
            const accessToken = await refreshTokens();
            if (accessToken) {
                authStore.setAccessToken(accessToken);
            } else {
                authStore.setLoaded();
            }
        }
        if (!state.accessToken) {
            loadTokens();
        }
    }, []);

    useEffect(() => {
        async function loadProfile() {
            const profile = await fetchProfile();
            authStore.setProfile(profile);
        }
        if (state.accessToken && !state.profile) {
            loadProfile();
        }
    }, [state.accessToken]);

    const login = (accessToken, profile) => {
        authStore.login(accessToken, profile);
    };

    const logout = () => {
        destroyToken().then(() => {
            authStore.logout();
        });
    };

    const refetchProfile = async () => {
        const profile = await fetchProfile();
        authStore.setProfile(profile);
    };

    const value = {
        accessToken: state.accessToken,
        profile: state.profile,
        loaded: state.loaded,
        isAuthenticated: state.isAuthenticated,
        refetchProfile,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
